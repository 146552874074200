import {motion} from 'framer-motion'

export const LastName = (props: any) => (
  <svg
    viewBox="0 0 309 159"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <motion.path
      initial={{pathLength: 0, fill: '#FFFFFF00'}}
      animate={{pathLength: 1, fill: '#B7B8D7'}}
      fill="#B7B8D7"
      transition={{
        duration: 1,
        delay: 2,
      }}
      className="hidden dark:block"
      d="M175.76 144V116H185.28C189.84 116 193.16 119.12 193.16 123.36C193.16 125.92 191.92 128.04 189.88 129.24V129.4C192.44 130.6 194.04 133 194.04 136.08C194.04 140.64 190.56 144 185.72 144H175.76ZM180.24 127.48H185.36C187.4 127.48 188.84 125.92 188.84 123.72C188.84 121.52 187.4 119.96 185.36 119.96H180.24V127.48ZM180.24 140.04H185.76C188 140.04 189.56 138.28 189.56 135.76C189.56 133.24 188 131.48 185.76 131.48H180.24V140.04ZM203.424 144H199.064L206.944 116H213.184L221.064 144H216.624L214.624 136.72H205.424L203.424 144ZM206.544 132.76H213.504L210.104 120.56H209.944L206.544 132.76ZM224.049 144V140.04H230.009V119.96H224.129V116H240.409V119.96H234.529V140.04H240.489V144H224.049ZM261.433 137.36V116H265.593V144H259.673L250.473 120.44H250.313V144H246.153V116H252.873L261.273 137.36H261.433ZM272.498 136.56H276.698C276.978 138.92 279.258 140.6 282.218 140.6C285.258 140.6 287.338 138.8 287.338 136.2C287.338 133.96 285.818 132.64 282.698 132.04L281.098 131.72C275.738 130.76 273.098 128.16 273.098 123.92C273.098 118.96 276.738 115.6 282.098 115.6C287.298 115.6 291.258 118.76 291.458 123.04H287.298C287.018 121 284.938 119.56 282.258 119.56C279.378 119.56 277.498 121.2 277.498 123.64C277.498 125.72 278.858 127 281.658 127.52L283.298 127.8C288.938 128.84 291.738 131.56 291.738 135.96C291.738 141.12 287.938 144.56 282.338 144.56C276.778 144.56 272.658 141.2 272.498 136.56Z"
    />
    <motion.path
      initial={{pathLength: 0, fill: '#FFFFFF00'}}
      animate={{pathLength: 1, fill: '#3E1345'}}
      fill="#3E1345'"
      transition={{
        duration: 1,
        delay: 2,
      }}
      className="dark:hidden"
      d="M175.76 144V116H185.28C189.84 116 193.16 119.12 193.16 123.36C193.16 125.92 191.92 128.04 189.88 129.24V129.4C192.44 130.6 194.04 133 194.04 136.08C194.04 140.64 190.56 144 185.72 144H175.76ZM180.24 127.48H185.36C187.4 127.48 188.84 125.92 188.84 123.72C188.84 121.52 187.4 119.96 185.36 119.96H180.24V127.48ZM180.24 140.04H185.76C188 140.04 189.56 138.28 189.56 135.76C189.56 133.24 188 131.48 185.76 131.48H180.24V140.04ZM203.424 144H199.064L206.944 116H213.184L221.064 144H216.624L214.624 136.72H205.424L203.424 144ZM206.544 132.76H213.504L210.104 120.56H209.944L206.544 132.76ZM224.049 144V140.04H230.009V119.96H224.129V116H240.409V119.96H234.529V140.04H240.489V144H224.049ZM261.433 137.36V116H265.593V144H259.673L250.473 120.44H250.313V144H246.153V116H252.873L261.273 137.36H261.433ZM272.498 136.56H276.698C276.978 138.92 279.258 140.6 282.218 140.6C285.258 140.6 287.338 138.8 287.338 136.2C287.338 133.96 285.818 132.64 282.698 132.04L281.098 131.72C275.738 130.76 273.098 128.16 273.098 123.92C273.098 118.96 276.738 115.6 282.098 115.6C287.298 115.6 291.258 118.76 291.458 123.04H287.298C287.018 121 284.938 119.56 282.258 119.56C279.378 119.56 277.498 121.2 277.498 123.64C277.498 125.72 278.858 127 281.658 127.52L283.298 127.8C288.938 128.84 291.738 131.56 291.738 135.96C291.738 141.12 287.938 144.56 282.338 144.56C276.778 144.56 272.658 141.2 272.498 136.56Z"
    />
    <motion.rect
      initial={{pathLength: 0, opacity: 0}}
      animate={{pathLength: 1, opacity: 1}}
      transition={{
        duration: 0.5,
        delay: 2,
      }}
      x={5.5}
      y={5.5}
      width={298}
      height={148}
      stroke="url(#paint0_linear_159_3)"
      strokeWidth={11}
    />
    <motion.rect
      initial={{pathLength: 0, opacity: 0}}
      animate={{pathLength: 1, opacity: 1}}
      transition={{
        duration: 0.5,
        delay: 2,
      }}
      x={5.5}
      y={5.5}
      width={298}
      height={148}
      className="dark:hidden"
      stroke="url(#paint0_linear_176_20)"
      strokeWidth={11}
    />
    <defs>
      <linearGradient
        id="paint0_linear_159_3"
        x1={183.974}
        y1={226.67}
        x2={249.497}
        y2={-0.160496}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#36386F" />
        <stop offset={1} stopColor="#758AC5" />
      </linearGradient>
      <linearGradient
        id="paint0_linear_176_20"
        x1="183.974"
        y1="226.67"
        x2="249.497"
        y2="-0.160496"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#53366F" />
        <stop offset="1" stopColor="#8075C5" />
      </linearGradient>
    </defs>
  </svg>
)
