export const BigStar = (props: any) => (
  <svg
    viewBox="0 0 159 257"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M79.5 0L89.0473 62.6352C92.5524 85.6305 109.033 104.528 131.338 111.128L159 119.313L132.279 127.22C109.492 133.963 92.8315 153.519 89.7951 177.088L79.5 257L69.2049 177.088C66.1685 153.519 49.5082 133.963 26.7212 127.22L0 119.313L27.6624 111.128C49.9673 104.528 66.4476 85.6305 69.9527 62.6352L79.5 0Z"
      fill="#FCE9EB"
    />
  </svg>
)
